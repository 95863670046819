
import { defineComponent, ref, watchEffect, onUnmounted } from "vue";
import { Apollo } from "@/core/services";
import { GET_BEST_SELLERS_LIST } from "../../../dashboard/graphql/Queries";
// import InnerLoader from "../../../../components/InnerLoader.vue";
import WidgetBestSellersByQuantity from "@/components/widgets/mixed/WidgetBestSellersByQuantity.vue";
import WidgetBestSellersByRevenue from "@/components/widgets/mixed/WidgetBestSellersByRevenue.vue";
import Table from "../../../../components/Table/Table.vue";
import { useI18n } from "vue-i18n";
import { asideTheme } from "@/core/helpers/config";

export default defineComponent({
	name: "Orders",
	components: {
		// InnerLoader,
		WidgetBestSellersByQuantity,
		WidgetBestSellersByRevenue,
		// Table,
	},
	setup() {
		const loader = ref(false);
		const i18n = useI18n();
		const activeTab = ref("first");
		const systemLocale = ref();
		const best_sellers: any = ref();
		const observable: any = ref();
		const glob: any = ref(asideTheme);

		const columns = ref([
			{
				label: "message.NAME",
				key: "name",
			},
			{
				label: "message.PRODUCT_NUMBER",
				key: "economic_number",
			},
			{
				label: "message.STATUS",
				key: "status",
			},
			{
				label: "message.TOTAL",
				key: "total",
			},
		]);

		const bestSellers = async (type: string) => {
			observable.value?.unsubscribe();
			loader.value = true;
			observable.value = Apollo.watchQuery({
				query: GET_BEST_SELLERS_LIST,
				fetchPolicy: "cache-and-network",
				nextFetchPolicy: "cache-and-network",
				errorPolicy: "all",
				variables: {
					type: type,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}
				best_sellers.value = JSON.parse(data?.best_sellers);
				loader.value = false;
			});
		};

		watchEffect(() => {
			systemLocale.value = i18n.fallbackLocale.value;
			if (activeTab.value == "first") {
				bestSellers("quantity");
			} else if (activeTab.value == "second") {
				bestSellers("total");
			}
		});

		onUnmounted(() => {
			observable.value?.unsubscribe();
		});

		return {
			loader,
			activeTab,
			columns,
			systemLocale,
			best_sellers,
			bestSellers,
			glob,
		};
	},
});
