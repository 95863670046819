
import { defineComponent, ref, onMounted, watchEffect, computed } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { Apollo } from "@/core/services";
import { MONTHLY_REVENUE } from "@/modules/dashboard/graphql/Queries";
import { config, asideTheme } from "@/core/helpers/config";
import { useI18n } from "vue-i18n";

export default defineComponent({
	name: "WidgetOrders",
	props: {
		widgetClasses: String,
		chartColor: String,
		chartHeight: String,
		count: String,
		background: String,
		locale: String,
		link: String,
		data: Object,
	},
	setup(props) {
		const color = ref(props.chartColor);
		const yearLabel = ref("");
		const system_locale = ref();
		const i18n = useI18n();
		const labelColor = getCSSVariableValue("--bs-gray-500");
		const borderColor = getCSSVariableValue("--bs-gray-200");
		const secondaryColor = getCSSVariableValue("--bs-gray-300");
		const baseColor = getCSSVariableValue("--bs-" + color.value);
		const glob = ref(asideTheme);
		const arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
		const colors = ["#33b2df", "#546E7A", "#d4526e", "#13d8aa", "#A5978B", "#2b908f", "#f9a3a4", "#90ee7e", "#f48024", "#69d2e7"];

		const chartOptions = ref({
			series: [
				{
					data: [],
				},
			],
			chart: {
				foreColor: glob.value == "dark" ? "#ffdd00" : "#000",
				fontFamily: "inherit",
				type: "bar",
				height: 250,
				toolbar: {
					show: false,
				},
			},

			plotOptions: {
				bar: {
					borderRadius: 4,
					barWidth: 200,
					horizontal: true,
					dataLabels: {
						position: "top",
					},
				},
			},

			dataLabels: {
				enabled: true,
				// position: "top",
				offsetX: 45,
				style: {
					colors: glob.value == "dark" ? ["#fff"] : ["#000"],
					fontSize: "10px",
				},
			},
			xaxis: {
				categories: [""],
				labels: {
					style: {
						colors: glob.value == "dark" ? "#ffdd00" : "#000",
						fontSize: "12px",
					},
				},
			},
			colors: ["#fd0"],
			grid: {
				borderColor: "transparent",
				row: {
					colors: ["transparent", "transparent"],
					opacity: 0.5,
				},
			},
		});

		const series = ref([
			{
				name: "Net Sale",
				data: [0],
				// colors: [""],
			},
		]);
		const monthlyRevenue = async () => {
			if (props.data) {
				const monthly_details = [];
				props.data.top_selling_products.forEach((element, index) => {
					chartOptions.value.xaxis.categories[index] = JSON.parse(element.name)[system_locale.value]?.name;
					series.value[0]["data"][index] = element.total;
				});
			}
		};

		watchEffect(() => {
			system_locale.value = i18n.fallbackLocale.value;
		});

		onMounted(() => {
			monthlyRevenue();
		});

		return {
			chartOptions,
			series,
			yearLabel,
			glob,
			// systemlLocale,
		};
	},
});
