import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-body d-flex flex-column p-0" }
const _hoisted_2 = {
  class: "flex-grow-1 card-p pb-0",
  style: {"background":"{{ background }}"}
}
const _hoisted_3 = { class: "d-flex flex-wrap align-items-start justify-content-between" }
const _hoisted_4 = { class: "d-flex flex-wrap align-items-center w-75" }
const _hoisted_5 = { class: "icon-holder mb-0" }
const _hoisted_6 = { class: "ml-4" }
const _hoisted_7 = { class: "text-muted fs-7 fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createBlock("div", {
    class: [_ctx.widgetClasses, "card order-widget yearly-sales-widget"],
    style: {"height":"95%"}
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("div", _hoisted_5, [
              _createVNode("img", {
                src: _ctx.icon,
                alt: _ctx.title
              }, null, 8, ["src", "alt"])
            ]),
            _createVNode("div", _hoisted_6, [
              _createVNode(_component_router_link, {
                to: _ctx.link == '#' ? '' : _ctx.link,
                class: [_ctx.link == '#' ? 'pointer' : '', "widget-heading fw-bolder fs-3"]
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(_ctx.title)), 1)
                ]),
                _: 1
              }, 8, ["to", "class"]),
              _createVNode("div", _hoisted_7, _toDisplayString(_ctx.$t(_ctx.titleDescription)), 1),
              (_ctx.getKey)
                ? (_openBlock(), _createBlock("div", {
                    key: 0,
                    class: [`text-${_ctx.chartColor}`, "fw-bolder main-content"]
                  }, _toDisplayString(_ctx.getKey), 3))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _createVNode(_component_apexchart, {
        class: "mixed-widget-7-chart card-rounded-bottom",
        type: "radialBar",
        height: _ctx.chartHeight,
        options: _ctx.chartOptions,
        series: _ctx.series
      }, null, 8, ["height", "options", "series"])
    ])
  ], 2))
}