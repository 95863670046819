
import { defineComponent, ref, onMounted, watchEffect } from 'vue';
import { getCSSVariableValue } from '@/assets/ts/_utils';
import { Apollo } from '@/core/services';
import { MONTHLY_REVENUE } from '@/modules/dashboard/graphql/Queries';
import { config, asideTheme } from '@/core/helpers/config';

export default defineComponent({
    name: 'WidgetOrders',
    props: {
        widgetClasses: String,
        chartColor: String,
        chartHeight: String,
        icon: String,
        title: String,
        titleDescription: String,
        count: String,
        background: String,
        locale: String,
        link: String
    },
    setup(props) {
        const color = ref(props.chartColor);
        const yearLabel = ref('');
        const labelColor = getCSSVariableValue('--bs-gray-500');
        const borderColor = getCSSVariableValue('--bs-gray-200');
        const secondaryColor = getCSSVariableValue('--bs-gray-300');
        const baseColor = getCSSVariableValue('--bs-' + color.value);
        const glob = ref(asideTheme);
        const arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        const chartOptions = ref({
            series: [
                {
                    name: 'Net Profit',
                    data: []
                }
            ],
            chart: {
                foreColor: glob.value == 'dark' ? '#ffdd00' : '#000',
                fontFamily: 'inherit',
                type: 'bar',
                height: props.chartHeight,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: ['50%'],
                    endingShape: 'rounded'
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: ['Jan'],
                title: {
                    text: ['']
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    style: {
                        colors: glob.value == 'dark' ? '#ffdd00' : '#000',
                        fontSize: '12px'
                    }
                }
            },
            yaxis: {
                y: 0,
                offsetX: 0,
                offsetY: 0,
                labels: {
                    style: {
                        colors: glob.value == 'dark' ? '#ffdd00' : '#000',
                        fontSize: '12px'
                    },
                    formatter: function(value) {
                        return value.toLocaleString('en-DK');
                    }
                }
            },
            fill: {
                type: 'solid',
                colors: '#ffdd00'
            },
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0
                    }
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0
                    }
                }
            },
            tooltip: {
                style: {
                    fontSize: '12px'
                }
            },
            colors: [baseColor, secondaryColor],
            grid: {
                padding: {
                    top: 10
                },
                borderColor: borderColor,
                strokeDashArray: 4,
                yaxis: {
                    lines: {
                        show: true
                    }
                }
            }
        });

        const series = ref([
            {
                name: 'Net Sale',
                data: [0]
            }
        ]);
        const monthlyRevenue = async () => {
            Apollo.watchQuery({
                query: MONTHLY_REVENUE,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                errorPolicy: 'all'
            }).subscribe(({ data, errors }) => {
                const monthly_details = JSON.parse(data.monthly_revenue);
                yearLabel.value = monthly_details.date;
                chartOptions.value.xaxis.categories.push(...monthly_details.months);
                chartOptions.value.xaxis.categories.shift();
                series.value[0]['data'].push(...arr);
                if (monthly_details.monthly_revenue != 0) {
                    monthly_details.monthly_revenue.forEach(revenue => {
                        const monthIndex = chartOptions.value.xaxis.categories.indexOf(revenue.date);
                        series.value[0]['data'][monthIndex] = revenue.order_total;
                    });
                }
            });
        };

        onMounted(() => {
            monthlyRevenue();
        });

        return {
            chartOptions,
            series,
            yearLabel,
            glob
        };
    }
});
