
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { Apollo } from "@/core/services";
import { DAILY_REVENUE } from "@/modules/dashboard/graphql/Queries";
import { CUSTOMER_DAILY_REVENUE } from "@/modules/customer/customers/graphql/Queries";

import { config, asideTheme } from "@/core/helpers/config";

export default defineComponent({
	name: "WidgetOrders",
	props: {
		widgetClasses: String,
		chartColor: String,
		chartHeight: String,
		icon: String,
		title: String,
		titleDescription: String,
		count: String,
		background: String,
		locale: String,
		link: String,
	},
	setup(props) {
		const color = ref(props.chartColor);
		const locale = ref(props.locale);
		const currency = ref("");
		const glob = ref(asideTheme);

		const chartOptions = ref({
			series: [
				{
					name: "Net Profit",
					data: [],
				},
			],
			chart: {
				zoom: {
					enabled: false,
				},
				foreColor: "#a1a5b7",
				shadow: {
					enabled: true,
					color: "#000",
					top: 18,
					left: 7,
					blur: 10,
					opacity: 1,
				},
				toolbar: {
					show: false,
				},
			},
			// colors: [`${config.value.asaide.theme == "dark" ? "#ffdd00" : "#000"}`],
			colors: ["#a1a5b7"],
			dataLabels: {
				enabled: true,
				style: {
					colors: ["#000"],
					fontSize: "10px",
				},
				formatter: function(value) {
					if (value == 0) {
						return value.toLocaleString("en-DK");
					}
					return `${currency.value} ${value.toLocaleString("en-DK")}`;
				},
			},
			stroke: {
				curve: "smooth",
				width: 1.5,
			},
			title: {
				text: "",
				align: "left",
			},
			grid: {
				borderColor: "transparent",
				row: {
					colors: ["#373737", "transparent"],
					opacity: 0.5,
				},
			},
			markers: {
				size: 4,
			},
			xaxis: {
				categories: ["0"],
				title: {
					text: [""],
				},
				labels: {
					offsetY: 10,
					rotate: -45,
					rotateAlways: true,
					style: {
						colors: glob.value == "dark" ? "#ffdd00" : "#000",
						fontSize: "12px",
					},
				},
			},
			yaxis: {
				y: 0,
				offsetX: 0,
				offsetY: 0,
				labels: {
					style: {
						colors: glob.value == "dark" ? "#ffdd00" : "#000",
						fontSize: "12px",
					},
					formatter: function(value) {
						return value.toLocaleString("en-DK");
					},
				},
			},
			theme: {
				palette: "palette7",
			},
			legend: {
				position: "top",
				horizontalAlign: "right",
				floating: true,
				offsetY: -25,
				offsetX: -5,
			},
		});

		const series = ref([
			{
				name: "Net Sale",
				data: [0],
			},
		]);

		const dailyRevenue = () => {
			Apollo.watchQuery({
				query: DAILY_REVENUE,
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				errorPolicy: "all",
			}).subscribe(({ data, errors }) => {
				const daily_details = JSON.parse(data.daily_revenue);
				chartOptions.value.xaxis.title.text.fill(daily_details.date_range);
				chartOptions.value.xaxis.categories.push(...daily_details.dates);
				const arr = Array(daily_details.dates.length).fill(0);
				series.value[0]["data"].push(...arr);
				daily_details.daily_revenue.forEach((revenue) => {
					series.value[0]["data"][chartOptions.value.xaxis.categories.indexOf(revenue.date)] = parseFloat(revenue.order_total);
				});
				series.value[0]["data"].shift();
				chartOptions.value.xaxis.categories.shift();
				chartOptions.value.xaxis.categories.forEach((date, index, array) => {
					array[index] = `${date} ${daily_details.mon}`;
				});
				currency.value = daily_details?.currency;
			});
		};

		onMounted(() => {
			dailyRevenue();
		});

		return {
			chartOptions,
			series,
			config,
			asideTheme,
			glob,
		};
	},
});
