import {gql} from "graphql-tag";

export const GET_WIDGETS = gql`
    query GetWidgets {
        widgets
    }
`;

export const DAILY_REVENUE = gql`
    query DailyRevenue {
        daily_revenue
    }
`;

export const MONTHLY_REVENUE = gql`
    query MonthlyRevenue {
        monthly_revenue
    }

`;

export const ANNUAL_REVENUE = gql`
    query AnnualRevenue {
        annual_revenue
    }
`;

export const ORDER_PAYMENT_METHODS = gql`
    query OrderPaymentMethods {
        order_payment_methods
    }
`;

export const TOP_BUYERS = gql`
    query TopBuyers {
        top_buyers
    }
`;

export const GET_BEST_SELLERS_LIST = gql`
    query GetBestSellers ( $type: String) {
        best_sellers (type:$type)
    }
`;

export const GET_MOST_VIEWED_LIST = gql`
    query GetMostViewedProducts {
        most_viewed
    }
`;

export default {GET_WIDGETS, DAILY_REVENUE, MONTHLY_REVENUE, ANNUAL_REVENUE , GET_BEST_SELLERS_LIST,GET_MOST_VIEWED_LIST}