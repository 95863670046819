
import { defineComponent, ref, watchEffect } from "vue";

import WidgetOrdersLatest10 from "@/components/widgets/mixed/WidgetOrdersLatest10.vue";
import DashboardBestSellers from "@/modules/dashboard/components/partials/DashboardBestSellers.vue";
import DashboardMostViewedProducts from "@/modules/dashboard/components/partials/DashboardMostViewedProducts.vue";
// import InnerLoader from "../../../components/InnerLoader.vue";

export default defineComponent({
	name: "dashboard",
	components: {
		WidgetOrdersLatest10,
		DashboardBestSellers,
		DashboardMostViewedProducts,
		// InnerLoader,
	},
	setup() {
		const activeTab = ref("first");
		const mostViewedProductTab = ref(false);
		const topSearchesTab = ref(false);
		const bestSellersTab = ref(false);

		watchEffect(() => {
			if (activeTab.value == "second" && !mostViewedProductTab.value) {
				mostViewedProductTab.value = true;
			} else if (activeTab.value == "third" && !topSearchesTab.value) {
				topSearchesTab.value = true;
			} else if (activeTab.value == "fourth" && !bestSellersTab.value) {
				bestSellersTab.value = true;
			}
		});

		return {
			activeTab,
			bestSellersTab,
			mostViewedProductTab,
			topSearchesTab,
		};
	},
});
