import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-xl-12 mt-0 latest-orders-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WidgetOrdersLatest10 = _resolveComponent("WidgetOrdersLatest10")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_DashboardMostViewedProducts = _resolveComponent("DashboardMostViewedProducts")!
  const _component_DashboardBestSellers = _resolveComponent("DashboardBestSellers")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      "tab-position": 'top',
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: `${_ctx.$t('message.LATEST_ORDERS')}`,
          name: "first"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_WidgetOrdersLatest10)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_tab_pane, {
          label: `${_ctx.$t('message.MOST_VIEWED_PRODUCTS')}`,
          name: "second"
        }, {
          default: _withCtx(() => [
            (_ctx.mostViewedProductTab == true)
              ? (_openBlock(), _createBlock(_component_DashboardMostViewedProducts, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_tab_pane, {
          label: _ctx.$t('message.BEST_SELLERS'),
          name: "fourth"
        }, {
          default: _withCtx(() => [
            (_ctx.bestSellersTab == true)
              ? (_openBlock(), _createBlock(_component_DashboardBestSellers, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}