
import { defineComponent, ref, onMounted } from "vue";
import { Apollo } from "@/core/services";
import {ORDER_PAYMENT_METHODS, TOP_BUYERS} from "@/modules/dashboard/graphql/Queries";
import { asideTheme } from "@/core/helpers/config";

export default defineComponent({
    name: "TopBuyers",
    props: {
        widgetClasses: String,
        chartColor: String,
        chartHeight: String,
        icon: String,
        locale: String,
        title: String,
        titleDescription: String,
        count: String,
        background: String,
        link: String,
    },
    setup(props) {
        const glob = ref(asideTheme);
        const chartOptions = {
            chart: {
                type: "donut"
            },
            labels: [
                {
                    colors: [glob.value == "dark" ? "#ffdd00" : "#000"],
                },
            ],
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 100,
                        },
                        legend: {
                            position: "bottom",
                        },
                    },
                },
            ],
            legend: {
                show: false,
                floating: true,
                fontSize: '12px',
                position: 'left',
                offsetX: 10,
                offsetY: -10,
                labels: {
                    useSeriesColors: true,
                },
            },
        };

        const series = ref([0]);


        const topBuyers = () => {
            Apollo.watchQuery({
                query: TOP_BUYERS,
                fetchPolicy: "network-only",
                nextFetchPolicy: "network-only",
                errorPolicy: "all"
            }).subscribe(({ data, errors }) => {
                const top_buyers = JSON.parse(data.top_buyers);
                top_buyers.forEach((top_buyer) => {
                    chartOptions.labels.push(top_buyer.name);
                    series.value.push(parseFloat(top_buyer.total));
                });
                chartOptions.labels.shift();
                series.value.shift();
            });
        };

        onMounted(() => {
            topBuyers();
        });

        return {
            series,
            chartOptions,
            glob,
        };
    },
});
