
import { defineComponent, ref, watchEffect, onMounted } from "vue";
import { Apollo } from "@/core/services";
import { useStore } from "vuex";
import { GET_LATEST_ORDER_LISTS } from "../../../modules/order/graphql/Queries";
import { useI18n } from "vue-i18n";
import Table from "../../Table/Table.vue";
import { useRouter } from "vue-router";
import InnerLoader from "../../InnerLoader.vue";

export default defineComponent({
	name: "Orders",
	components: {
		Table,
		InnerLoader,
	},
	setup() {
		const store = useStore();
		const i18n = useI18n();
		const loader = ref(false);
		const orders: any = ref([]);
		const system_locale = ref();
		const router = useRouter();
		const permissions = store.getters.getPermissions;

		const columns = ref([
			{
				label: "message.CUSTOMERS",
				key: "name",
			},
			{
				label: "message.STATUS",
				key: "status",
			},
			{
				label: "message.E_CONOMIC",
				key: "economic",
			},
			{
				label: "message.SHIPMENT",
				key: "shipment",
			},
			{
				label: "message.TOTAL",
				key: "total",
				textAlignment: "end",
			},
			{
				label: "message.CREATED_AT",
				key: "created_at",
			},
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);

		watchEffect(() => {
			system_locale.value = i18n.fallbackLocale.value;
		});

		const Orders = () => {
			loader.value = true;
			Apollo.watchQuery({
				query: GET_LATEST_ORDER_LISTS,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}
				orders.value = data.orders_latest;
				loader.value = false;
			});
		};

		onMounted(() => {
			Orders();
		});

		const handleView = (order: Record<any, any>) => {
			router.push({ name: "order_details", params: { uuid: order.uuid } });
		};

		return {
			orders,
			system_locale,
			columns,
			permissions,
			handleView,
			loader,
		};
	},
});
