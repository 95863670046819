
import { defineComponent, ref, watchEffect, onMounted } from "vue";
import { Apollo } from "@/core/services";
import { GET_MOST_VIEWED_LIST } from "../../../dashboard/graphql/Queries";
import { useI18n } from "vue-i18n";
import Table from "../../../../components/Table/Table.vue";
// import InnerLoader from "../../../../components/InnerLoader.vue";

export default defineComponent({
	name: "Orders",
	components: {
		Table,
		// InnerLoader,
	},
	setup() {
		const i18n = useI18n();
		const loader = ref(false);
		const most_viewed: any = ref();
		const systemLocale = ref();

		const columns = ref([
			{
				label: "message.NAME",
				key: "name",
			},
			{
				label: "message.PRODUCT_NUMBER",
				key: "economic_number",
			},
			{
				label: "message.STATUS",
				key: "status",
			},
			{
				label: "message.TOTAL_VIEWS",
				key: "total",
			},
		]);

		watchEffect(() => {
			systemLocale.value = i18n.fallbackLocale.value;
		});

		const mostViewed = () => {
			loader.value = true;
			Apollo.watchQuery({
				query: GET_MOST_VIEWED_LIST,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-and-network",
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}
				most_viewed.value = JSON.parse(data?.most_viewed);
				loader.value = false;
			});
		};

		onMounted(() => {
			mostViewed();
		});

		return {
			most_viewed,
			systemLocale,
			columns,
			loader,
		};
	},
});
