import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header card-p" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3" }
const _hoisted_5 = { class: "card-body p-0" }
const _hoisted_6 = { class: "table-responsive" }
const _hoisted_7 = {
  key: 0,
  class: "badge badge-light-primary"
}
const _hoisted_8 = {
  key: 1,
  class: "badge badge-light-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("h3", _hoisted_3, [
        _createVNode("span", _hoisted_4, _toDisplayString(`${_ctx.$t("message.MOST_VIEWED_PRODUCTS")}`), 1)
      ])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_Table, {
          columns: _ctx.columns,
          rows: _ctx.most_viewed,
          padding: 5,
          loading: _ctx.loader
        }, {
          "cell-name": _withCtx(({row:item}) => [
            _createVNode("div", null, _toDisplayString(JSON.parse(item?.name)[`${_ctx.systemLocale}`]?.name), 1)
          ]),
          "cell-status": _withCtx(({row:item}) => [
            (item?.status == 1)
              ? (_openBlock(), _createBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("message.ACTIVE")), 1))
              : (_openBlock(), _createBlock("span", _hoisted_8, _toDisplayString(_ctx.$t("message.INACTIVE")), 1))
          ]),
          _: 1
        }, 8, ["columns", "rows", "loading"])
      ])
    ])
  ]))
}