
import { defineComponent, ref, onMounted } from 'vue';
import { Apollo } from '@/core/services';
import { ORDER_PAYMENT_METHODS } from '@/modules/dashboard/graphql/Queries';
import { asideTheme } from '@/core/helpers/config';

export default defineComponent({
    name: 'OrderPaymentMethods',
    props: {
        widgetClasses: String,
        chartColor: String,
        chartHeight: String,
        icon: String,
        locale: String,
        title: String,
        titleDescription: String,
        count: String,
        background: String,
        link: String
    },
    setup(props) {
        const glob = ref(asideTheme);
        const chartOptions = {
            chart: {
                type: 'radialBar'
            },
            plotOptions: {
                radialBar: {
                    offsetY: 25,
                    startAngle: 0,
                    endAngle: 300,
                    hollow: {
                        background: 'transparent',
                        margin: 15,
                        size: '50%'
                    },
                    track: {
                        background: 'transparent',
                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            color: '#999',
                            opacity: 1,
                            blur: 2
                        }
                    },
                    dataLabels: {
                        name: {
                            fontSize: '22px'
                        },
                        value: {
                            fontSize: '16px',
                            color: glob.value == 'dark' ? '#A1A5B7' : '#000',
                            formatter: function(val, data) {
                                let sum = 0;
                                let per_val = 0;
                                const arr = data.config.series;
                                arr.forEach(element => {
                                    sum += element;
                                });
                                per_val = (val / sum) * 100;
                                return `${val}(${parseFloat(String(per_val)).toFixed(2)}%)`;
                            }
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            color: glob.value == 'dark' ? '#A1A5B7' : '#000',
                            formatter: function(data) {
                                let sum = 0;
                                const arr = data.config.series;
                                arr.forEach(element => {
                                    sum += element;
                                });
                                return sum;
                            }
                        }
                    }
                }
            },
            stroke: {
                lineCap: 'round'
            },
            legend: {
                show: true,
                floating: true,
                fontSize: '12px',
                position: 'left',
                offsetX: 0,
                offsetY: -20,
                labels: {
                    useSeriesColors: true
                }
            },
            colors: ['#5384e6', '#fd0', '#77fcac'],
            labels: ['']
        };

        const series = ref([0]);

        const orderPaymentMethods = () => {
            Apollo.watchQuery({
                query: ORDER_PAYMENT_METHODS,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                errorPolicy: 'all'
            }).subscribe(({ data, errors }) => {
                const locale = props.locale;
                const order_payment_methods = JSON.parse(data.order_payment_methods);
                if (order_payment_methods) {
                    order_payment_methods.forEach(order_payment_method => {
                        const name = JSON.parse(order_payment_method.name)[`${locale}`].name;
                        chartOptions.labels.push(name);
                        series.value.push(parseFloat(order_payment_method.total));
                    });
                    chartOptions.labels.shift();
                    series.value.shift();
                }
            });
        };

        onMounted(() => {
            orderPaymentMethods();
        });

        return {
            series,
            chartOptions,
            glob
        };
    }
});
